@import '../../../scss/variables';

.address-modal-app {
  .address-modal {
    background: rgba(0, 0, 0, 0.5);

    &__loader {
      padding: 10px 0px 20px;
    }

    &__info {
      &-suggestion,
      &-perfect {
        margin-top: 15px;
      }

      &-invalid {
        margin-top: 20px;
      }

      &-suggestion,
      &-perfect,
      &-invalid {
        div {
          text-align: center;
          width: 220px;
        }
      }
    }

    &__content {
      padding: 10px 0px;
    }

    &__button {
      width: 257px;
    }

    &__header {
      border-bottom: 5px solid #808080;
    }

    &__body {
      font-size: 1.6rem;
      padding: 20px 20px 10px 20px;
    }

    &__footer {
      margin-top: inherit;
      padding: 20px 0px 10px;
    }

    &__help-text {
      margin-top: 30px;
      margin-right: 4%;
    }

    .modal-header {
      text-align: center;
      border-bottom: 5px solid #808080;
    }

    .modal-body {
      font-size: 1.6rem;
      padding: 20px 20px 10px 20px;
    }

    .modal-dialog {
      @media screen and (min-width: 768px) {
        width: 650px;
      }
    }

    .content-title {
      color: $blue;
      font-size: 1.9rem;
      font-weight: bold;
    }

    .invalid-modal-container {
      z-index: 1;
      width: 100%;
      height: 100%;
      top: -15%;
      left: 0;
      position: absolute;
      visibility: visible;
      display: block;
    }

    .suggested-modal-container {
      z-index: 1;
      width: 100%;
      height: 100%;
      top: -28%;
      left: 0;
      position: absolute;
      visibility: visible;
      display: block;
    }

    .warning-dialog {
      width: 400px;
      position: relative;
      margin: 0 auto;
      top: 0;
    }

    .warning-content {
      border: solid 3px #808080d9;
      border-radius: 8px;
      text-align: center;
    }

    .warning-desc {
      margin: 4% 14% 8% 15%;
      font-size: 16px;
      font-weight: 600;
    }

    .warning-btn {
      background: #FF6E19;
      border-bottom-color: #FF6E19;
      font-size: 16px;
      margin-bottom: 9%;
      padding: 0;
    }

    .warning-link {
      color: #01adc9;
    }

    .btn-orange {
      background: #FF6E19;
      border-bottom-color: #FF6E19
    }

    .address-edit-container {
      margin: 3% 10% 7% 10%;
    }

    .validate-btn {
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .warning-text {
      color: #FF6E19;
      font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      .warning-dialog {
          width: 325px;
      }
      .invalid-modal-container {
        top: -6%;
      }
      .suggested-modal-container {
        top: -14%;
      }
    }
  }
}
